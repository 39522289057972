import Link from 'next/link'
import styled from 'styled-components'

export default function FourOhFour() {
  return (
    <Container>
      <Row>
        <NotFound>
          <Title>Không tìm thấy trang</Title>
          <StyledLink href="/">Quay lại trang chủ</StyledLink>
        </NotFound>
      </Row>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotFound = styled.div`
  text-align: center;
`

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`

const StyledLink = styled(Link)`
  color: #0070f3;
  text-decoration: underline;
`
